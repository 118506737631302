<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import InputGroup from "@/components/newforms/input-group";
import SelectGroup from "@/components/newforms/select-group";

import SaveButton from "@/components/buttons/save-button";
import LoadingSpinner from '@/components/Loading';
import ImageFile from '@/components/buttons/image-file';

/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        InputGroup,
        SelectGroup,
        SaveButton,
        LoadingSpinner,
        ImageFile
    },
    data() {
        const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';

        return {
            id: id,
            title: `Agent ${modeName}`,
            modeName,
            items: [
                {
                    text: "Agent"
                },
                {
                    text: modeName,
                    active: true,
                },
            ],
            data: {
                registration_number: '',
                agency_id: '',
                member_id: '',
                status: '',
            },
            file: [],
            // agency_proof: [],
            agent_proof: [],
            errors: {},
            withAgencyOptions: [
                { name: 'With Agency', value: 1 },
                { name: 'Free Agent', value: 0 },
            ],
            agency_options: [],
            member_options: [],
            status_options: [
                { text: 'Verified', value: 1 },
                { text: 'Not Verified', value: 0 },
            ],
            redirect_page: false,
            button_click: false,
            isLoading: false,
        };
    },
    computed: {

    },
    async mounted() {
        this.isLoading = true;
        await this.loadAgencyOptions();
        await this.loadMemberOptions();

        if (this.modeName !== 'Add') {
            const result = await axios.get(`${process.env.VUE_APP_APIURL}/agent/${this.id}`, {
                headers: authHeader(),
            });

            if (result && result.data['code'] === 'success') {
                const data = result.data['data'];

                if (data.agent_pic && Array.isArray(data.agent_pic)) {
                    this.agent_proof = data.agent_pic.map((img, index) => ({
                        id: index,
                        source: 'url',
                        image: img.image,
                    }));                    
                }

                for (const [key, item] of Object.entries(data)) {
                    if (key in this.data) {
                        this.data[key] = item;
                    }
                }
            }
        }
        this.isLoading = false;
    },
    methods: {
        getFileName(url) {
            if (!url) return "Invalid URL";
            try {
                const urlObj = new URL(url);
                const pathArray = urlObj.pathname.split('/');
                return pathArray[pathArray.length - 1];
            } catch (error) {
                console.error("Invalid URL:", url, error);
                return "Invalid URL";
            }
        },
        handleFileChange(event) {
            const files = event.target.files || event;
            if (files.length > 0) {
                Array.from(files).forEach((file) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.agent_proof.push({
                            id: Date.now(), // Unique ID
                            source: 'base64',
                            file: file, // Original file object
                            image: e.target.result, // Base64 string
                        });
                    };
                    reader.readAsDataURL(file);
                });
            }
        },
        async save() {
            this.errors = {};
            this.isLoading = true;
            
            const formData = new FormData();
            Object.keys(this.data).forEach(e => {
                formData.append(e, this.data[e]);
            });

            this.agent_proof.forEach((proof, index) => {
                formData.append(`agent_proof[${index}]`, proof.image);
            });

            let result;
            if (this.modeName === 'Add') {
                result = await axios.post(`${process.env.VUE_APP_APIURL}/agent`, formData, {
                    headers : {
                        'Content-Type': 'application/json',
                        'Authorization': authHeader()["Authorization"]
                    }
                });
            } else {
                result = await axios.put(`${process.env.VUE_APP_APIURL}/agent/${this.id}`, formData, {
                    headers : {
                        'Content-Type': 'application/json',
                        'Authorization': authHeader()["Authorization"]
                    }
                });
            }

            if (result) {
                this.button_click = false;
                if (result.data['code'] === 'success') {
                    if (this.modeName === 'Add') {
                        this.isLoading = false;
                        this.$router.push({ 'name': "Agent" });
                    } else {
                        this.isLoading = false;
                        this.$refs.messageModal.showModal('Your record has been updated successfully');
                    }
                } else if (result.data['code'] === 'permission_denied') {
                    this.isLoading = false;
                    this.$refs.messageModal.showModal('Permission Denied');
                } else if (result.data['code'] === 'invalid_field') {
                    this.isLoading = false;
                    this.errors = result.data['errors'];
                } else if (result.data['code'] === 'invalid_member') {
                    this.isLoading = false;
                    this.errors = result.data['errors'];
                } else if (result.data['code'] === 'exceed_max_files') {
                    this.isLoading = false;
                    this.$refs.messageModal.showModal('Maximum 5 files are allowed');
                }
            }

            this.isLoading = false;
        },

        async loadAgencyOptions() {
            await axios.get(`${process.env.VUE_APP_APIURL}/agency-options`, {
                headers: authHeader(),
            }).then(e => {
                if (e.status == 200) {
                    const { data } = e.data;
                    this.agency_options = data;
                    console.log('agency_options', this.agency_options)
                }
            });
        },
        async loadMemberOptions() {
            await axios.get(`${process.env.VUE_APP_APIURL}/member-list`, {
                headers: authHeader(),
            }).then(e => {
                if (e.status == 200) {
                    const { data } = e.data;
                    const val = data.map(e => { return {"text": e.full_name, "value": e.id} });
                    this.member_options = val;
                }
            });
        },
        redirect() {
            if (this.redirect_page)
                this.$router.push({ 'name': "Agent View", params: {id: this.id} });
        },
        handleDeleteImage(id) {
            console.log('id', id)
            this.agent_proof = this.agent_proof.filter((doc) => doc.id != id);
        },
    }
};
</script>

<template>
    <Layout>
        <LoadingSpinner :isLoading="isLoading" />
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" @CloseBtn="redirect" />
        <div class="card" style="border-radius: 15px;">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-md-4 mb-2">
                        <SelectGroup id="member_id" name="member_id" v-model="data.member_id" label="Member" placeholder="Select a member" :options="member_options" :error="errors.member_id" :disabled="modeName !== 'Add'" />
                    </div>

                    <div class="col-12 col-md-4 mb-2">
                        <InputGroup id="registration_number" name="registration_number" v-model="data.registration_number" label="Registration Number" placeholder="Registration Number" :error="errors.registration_number"/>
                    </div>
                    <div class="col-12 col-md-4 mb-2">
                        <SelectGroup id="agency_id" name="agency_id" v-model="data.agency_id" label="Agency (Optional)" placeholder="Select An Agency" :options="agency_options" :error="errors.agency_id"/>
                    </div>
                    <!-- <div class="col-12 col-md-4 mb-2">
                        <SelectGroup id="member_id" name="member_id" v-model="data.member_id" label="Member" placeholder="Select An Agency" :options="member_options" :error="errors.member_id"/>
                    </div> -->
                </div>
                <div class="row">
                    <div class="col-12 col-md-4 mb-2 mt-2">
                        <label class="mb-0">Upload Proof of Registration</label>
                        <b-form-file multiple accept="image/*" @change="handleFileChange" :value="agent_proof"></b-form-file>
                    </div>

                    <div class="col-12 col-md-4 mt-2">
                        <SelectGroup id="status" name="status" v-model="data.status" label="Verification Status" :options="status_options" :error="errors.status"/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 mt-2" v-for="doc in agent_proof" :key="doc.id">
                        <ImageFile
                            :img_url="doc.image"
                            :file_name="doc.source === 'url' ? getFileName(doc.image) : doc.file?.name"
                            :file_id="parseInt(doc.id)"
                            @deleteImg="handleDeleteImage(doc.id)"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 mt-3">
                        <SaveButton @click="save" />
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>